import React, { useEffect, useState } from 'react';
import styles from './EditForm.module.css';
import inputStyles from 'assets/styles/input.module.css';
import { ListingFormType as Form } from 'pages/Listings';

interface Props {
  form: [Form, React.Dispatch<React.SetStateAction<Form>>];
};

const EditForm: React.FC<Props> = ( p ) => {
  const [form, setForm] = p.form;

  const onChange = (e: React.SyntheticEvent) => {
    var input: HTMLInputElement | HTMLTextAreaElement;
    if(e.currentTarget instanceof HTMLInputElement)
      input = e.currentTarget as HTMLInputElement;
    else
      input = e.currentTarget as HTMLTextAreaElement;

    setForm(prev => {
      var update = { ...prev };
      update[input.name] = input.type !== 'checkbox' ? input.value : ((input as HTMLInputElement).checked ? 1 : 0);
      return update;
    });
  }
  
  return (
    // key required to reset default input values
    <form id='edit_listings_form' key={form.id} className={styles.listingData}>
      <div className={styles.listingDataRow}>
        <TitleInput value={form.title} onChange={onChange} />
        <PriceInput value={form.price} onChange={onChange} />
      </div>
      <div className={styles.listingDataRow}>
        <YearInput value={form.year} onChange={onChange} />
        <MakeInput value={form.make} onChange={onChange} />
        <ModelInput value={form.model} onChange={onChange} />
      </div>
      <div className={styles.listingDataRow}>
        <OdometerInput value={form.odometer} onChange={onChange} />
        <BodyTypeInput value={form.body_type} onChange={onChange} />
      </div>
      <div className={styles.listingDataRow} style={{ flexGrow: 1 }}>
        <DescriptionInput value={form.description} onChange={onChange} />
      </div>
      <div className={styles.listingDataRow} style={{ justifyContent: 'flex-start' }}>
        <IsSoldInput value={form.is_sold} onChange={onChange} />
      </div>
    </form>
  );
}

interface InputProps {
  value: string | number;
  checked?: boolean;
  onChange?: (e: React.SyntheticEvent) => void;
}

const TitleInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Ad Title' name='title' value={p.value} onChange={p.onChange}
      required={true} maxLength={255} style={{ flexBasis: '67%' }} />
  )
};

const PriceInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Price' name='price' value={p.value} onChange={p.onChange}
      type='number' required={true} min={0} maxLength={20} />
  );
};

const YearInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Year' name='year' value={p.value} onChange={p.onChange}
      type='number' required={true} min={1886} max={new Date().getFullYear() + 5} />
  );
};

const MakeInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Make' name='make' value={p.value} onChange={p.onChange}
      required={true} maxLength={20} />
  );
};

const ModelInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Model' name='model' value={p.value} onChange={p.onChange}
      required={true} maxLength={20} />
  );
};

const OdometerInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Odometer (KM)' name='odometer' value={p.value} onChange={p.onChange}
      type='number' required={true} min={0} maxLength={20} />
  );
}

const BodyTypeInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Body Type' name='body_type' value={p.value} onChange={p.onChange}
      maxLength={20} />
  );
}

const DescriptionInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput label='Description' name='description' value={p.value} onChange={p.onChange}
      type='textArea' maxLength={30000} />
  );
}

const IsSoldInput: React.FC<InputProps> = ( p ) => {
  return (
    <FormInput
      label='Mark as sold' 
      name='is_sold' type='checkbox' value='is_sold' checked={p.value ? true : false} onChange={p.onChange}
      style={{ flexGrow: 'unset', width: 'fit-content', flexDirection: 'row', alignItems: 'center' }}
    />
  );
}

type FormInputProps = {
  label: string;
  name?: string;
  type?: string;
  required?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  value: string | number;
  checked?: boolean;
  style?: React.CSSProperties;
  onChange?: (e: React.SyntheticEvent) => void;
}

const FormInput: React.FC<FormInputProps> = ( p ) => {
  return (
    <div className={styles.listingDatum} style={p.style}>
      <div style={{ whiteSpace: 'nowrap' }}>{p.label}</div>
      {p.type !== 'textArea' ? 
        <input
          style={p.type === 'checkbox' ? { marginLeft: '0.5em' } : {}}
          className={inputStyles.ctn} 
          type={p.type ?? 'text'} 
          name={p.name ?? p.label} 
          required={p.required ?? false}
          maxLength={p.maxLength}
          min={p.min}
          max={p.max}
          defaultValue={p.value}
          defaultChecked={p.checked ?? false}
          onChange={p.onChange}
        /> : 
        <textarea 
          className={inputStyles.ctn} 
          style={{ resize: 'none', height: '100%', whiteSpace: 'pre-wrap' }} 
          name={p.name ?? p.label} 
          required={p.required ?? false}
          maxLength={p.maxLength}
          defaultValue={p.value}
          onChange={p.onChange}
        />
      }
    </div>
  );
}

export default EditForm;
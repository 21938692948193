import React, { useState, useRef, useEffect, useCallback, SyntheticEvent } from 'react';
import styles from './EditMedia.module.css';
import { Button, buttonStyles, FiniteCarousel, TaggedImage } from 'components';
import { ClipLoader as LoadingSpinner } from 'react-spinners';
import { MdAddToPhotos as AddMediaIcon } from 'react-icons/md';
import { FaChevronLeft as ArrowLeft } from 'react-icons/fa';
import { FaChevronRight as ArrowRight } from 'react-icons/fa';
import { FaWindowClose as DeleteIcon } from 'react-icons/fa';
import { SalesAPI } from 'services';
import imageCompression from 'browser-image-compression';

type Props = {
  id?: string;
  media: [Array<File | string>, React.Dispatch<React.SetStateAction<(File | string)[]>>];
  deletedMedia: [Array<string>, React.Dispatch<React.SetStateAction<string[]>>];
  isSold: boolean;
};

type MediaStateType = {
  orderChange: boolean;
  deletedMedia: string[];
}

const EditMedia: React.FC<Props> = ( p ) => {
  const [isLoading, setIsLoading] = useState<boolean | [number, number, number]>(false);
  const [mediaBuffer, setMediaBuffer] = p.media;
  const [deletedMedia, setDeletedMedia] = p.deletedMedia;
  const uploadRef = useRef<HTMLInputElement>(null);
  const [focusIdx, setFocusIdx] = useState<number>(0);
  const [inlineBuffer, setInlineBuffer] = useMediaUploader(uploadRef, mediaBuffer, setMediaBuffer, setIsLoading, setFocusIdx, p.id);
  

  const handleUpload = useCallback(() => {
    const fileInput = uploadRef.current;
    if(!fileInput || !fileInput.files)
      return;
    fileInput.click();
  }, [uploadRef.current])


  const handleFocus = (i: number) => {
    setFocusIdx(i);
  };

  const changeOrder = (left: boolean) => {
    var offset: number;
    if(left && focusIdx > 0) {
      offset = -1;
    } else if(!left && focusIdx < mediaBuffer.length - 1) {
      offset = 1;
    } else {
      return;
    }
    setInlineBuffer(prev => {
      var update = [...prev];
      var temp = update[focusIdx + offset];
      update[focusIdx + offset] = update[focusIdx];
      update[focusIdx] = temp;
      
      return update;
    });
    setMediaBuffer(prev => {
      var update = [...prev];
      var temp = update[focusIdx + offset];
      update[focusIdx + offset] = update[focusIdx];
      update[focusIdx] = temp;
      
      return update;
    });
    setFocusIdx(prev => prev + offset);
  }

  const deleteImage = (e: SyntheticEvent) => {
    if(p.id && typeof mediaBuffer[focusIdx] === 'string') {
      setDeletedMedia(prev => {
        const update = [...prev, mediaBuffer[focusIdx] as string];
        return update;
      })
    }
    setMediaBuffer(prev => {
      const update = [...prev];
      update.splice(focusIdx, 1);
      return update;
    });
    setInlineBuffer(prev => {
      const update = [...prev];
      update.splice(focusIdx, 1);
      return update;
    })
    if(focusIdx)
      setFocusIdx(prev => prev - 1);
  }

  return (
    <div className={styles.imageData}>
      <div className={styles.focusedImageCtn}>
        <TaggedImage 
          image={inlineBuffer.length ? `${inlineBuffer[focusIdx]}` : ''}
        />
        <div style={{ display: mediaBuffer.length > 0 ? 'flex' : 'none', justifyContent: 'center' }}>
          <div className={styles.deleteImgCtn}>
            Delete Image
            <div className={styles.deleteImgBtn} onClick={deleteImage}> <DeleteIcon /> </div>
          </div>
          <div className={styles.orderBtnCtn}>
            <div className={styles.orderBtn} onClick={(e) => changeOrder(true)}> <ArrowLeft /> </div>
            <div style={{ margin: '0 1em', color: 'white' }}> Change Order </div>
            <div className={styles.orderBtn} onClick={(e) => changeOrder(false)}> <ArrowRight /> </div>
          </div>
        </div>
      </div>
      <div className={styles.imageReel}>
        <FiniteCarousel.Container>
          {inlineBuffer.map((media, i) => {
            return (
              <FiniteCarousel.Item className={i === focusIdx ? styles.focusedReelItem : ''} key={i}>
                <TaggedImage image={`${media}`} size='cover' onClick={(e) => handleFocus(i)} />
              </FiniteCarousel.Item>
            );
          })}
        </FiniteCarousel.Container>
      </div>
      <input ref={uploadRef} type='file' multiple style={{ display: 'none' }}/> 
      {isLoading === false &&
        <Button label='Add Photos / Videos' className={styles.btn} onClick={handleUpload} style={{ width: 'fit-content'}}>
            <AddMediaIcon />
        </Button>
      }
      {isLoading !== false &&
        <Button label='Compressing images...' className={styles.btn} onClick={handleUpload} style={{ width: 'fit-content', pointerEvents: 'none', cursor: 'not-allowed'}}>
            {isLoading === true ? <LoadingSpinner size={'1em'} className={buttonStyles.icon} style={{ marginLeft: 0 }} /> : <>{`${isLoading[0]}% (${isLoading[1]} of ${isLoading[2]} done)`}</> }
        </Button>
      }
    </div>
  );
}

// todo: handle duplicate file names
const useMediaUploader = (uploadRef: React.RefObject<HTMLInputElement>,
                          mediaBuffer: (File | string)[],
                          setMediaBuffer: React.Dispatch<React.SetStateAction<(File | string)[]>>, 
                          setIsLoading: React.Dispatch<React.SetStateAction<boolean | [number, number, number]>>,
                          setFocusIdx: React.Dispatch<React.SetStateAction<number>>,
                          id?: string) => {

  const [inlineBuffer, setInlineBuffer] = useState<string[]>([]);
  
  const addToInlineBuffer = (files: (File | string)[], deletePlaceHolder: boolean = false) => {
    setIsLoading(true);
    const buf = files.map(async (f, i) => {
      if(f instanceof (File)) {
        return new Promise<string>((resolve, reject) => {
          var fr = new FileReader();
          fr.readAsDataURL(f);
          fr.onload = () => resolve(fr.result as string);
          fr.onerror = (err) => reject(err);
        })
      } else {
        return SalesAPI.getMediaURL(id as string, f);
      }
    });
    Promise.allSettled(buf).then((res) => {
      const update = new Array<string>(0);
      for(var i = 0; i < res.length; i++) {
        if(res[i].status === 'fulfilled') 
          update.push((res[i] as PromiseFulfilledResult<string>).value);
        else {
          console.log('Error converting', files[i]);
          files.splice(i, 1);
        }
      }
      if(deletePlaceHolder)
        setInlineBuffer([...update]);
      else
        setInlineBuffer(prev => [...prev, ...update]);

    }).finally(() => {
      setIsLoading(false);
    });
  }

  function checkExtension(f: File) {
    var result = (f.type !== 'image/jpeg' && f.type !== 'image/png' && f.type !== 'image/jpg' && f.type !== 'image/webp');
    if(result) {
      window.alert(`${f.name} is an unsupported file. Please provide an image with extension .jpeg, .jpg, .png, or .webp.`);
    }
    return result;
  }

  function checkSize(f: File) {
    var result = f.size >= 5000000;
    if(result) {
      window.alert(`${f.name} is too large. Please provide an image under 5 MB in size.`);
    }
    return result;
  }

  useEffect(() => {
    if(!uploadRef.current)
      return;
    var upload = uploadRef.current;
    upload.onchange = async () => {
      if(!upload.files)
        return;
      var files = [...upload.files];
      setIsLoading(true);
      const ready = [];
      // console.log('Pre-compressed', files[files.length - 1]);
      for(var i = 0; i < files.length; i++) {
        // check if file name already exists in the buffer and remove it if so
        if(checkIfDuplicate(files[i], mediaBuffer, ready) || checkExtension(files[i]) || checkSize(files[i])) {
          files.splice(i, 1);
          i--;
        } else if(files[i] instanceof File) {
          const compressed = await convertToWEBP(files[i], setIsLoading, i, files.length);
          if(compressed) {
            files[i] = compressed;
            ready.push(files[i].name);
          } else {
            files.splice(i, 1);
            i--;
          }
        }
      }
      // console.log('Post-compression', files[files.length - 1]);
      if(files.length === 0) {
        setIsLoading(false);
        return;
      }

      addToInlineBuffer(files, !mediaBuffer.length);

      setMediaBuffer(prev => {
        const update = [...prev, ...files];
        return update;
      });
      setFocusIdx(mediaBuffer.length);
      setIsLoading(false);
    }
  }, [uploadRef.current, mediaBuffer])

  useEffect(() => {
    if(mediaBuffer.length === 0) {
      // setInlineBuffer([ SalesAPI.getMediaPlaceholderURL() ]);
      return;
    }
    addToInlineBuffer(mediaBuffer);
    setFocusIdx(0);
  }, []);

  useEffect(() => {
    if(mediaBuffer.length === 0) {
      setInlineBuffer([ SalesAPI.getMediaPlaceholderURL() ]);
    }
  }, [mediaBuffer.length])

  useEffect(() => {
    console.log('Inline image buffer', inlineBuffer);
  }, [inlineBuffer])

  return [inlineBuffer, setInlineBuffer] as const;
}

function getNameFromDropboxURL(url: string) {
  return url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.webp'));
}

function checkIfDuplicate(f: File, buf: (File | string)[], extra: string[]): boolean {
  console.log('EXTRA', extra);
  var id = f.name.replace(/\.[^\.]+$/, "");
  var isDup = buf.findIndex(item => {
    var target = item instanceof File ? item.name.replace(/\.[^\.]+$/, "") : getNameFromDropboxURL(item);
    // console.log(`Comparing ${target} against ${id}`);
    return target === id;
  }) != -1 || extra.findIndex(item => { 
    var target = item.replace(/\.[^\.]+$/, "");
    // console.log(`Comparing ${target} against ${id}`);
    return target === id;
  }) != -1;
  if(isDup)
    window.alert(`${f.name} already shares a name with another file in your list. Make sure all file names are unique (before file extensions).`);
  return isDup;
}

async function convertToWEBP(f: File, onProgress: any, index: number, total: number) {
  const updateLoading = (pct: number) => {
    onProgress([pct, index, total]);
  }

  try {
    const compressed = await imageCompression(f,
      { maxSizeMB: 0.5, alwaysKeepResolution: true, fileType: 'image/webp', onProgress: updateLoading, maxIteration: 10, maxWidthOrHeight: 1100 });
    return new File([compressed], `${f.name.replace(/\.[^/.]+$/, "")}.webp`);
  } catch(err) {
    console.log(err);
    return null;
  }
}

export default EditMedia;
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useData } from 'contexts';
import { ListingsGrid, EditListings } from 'pages/Listings';
import { User } from 'firebase/auth';
import { ClipLoader as LoadingSpinner } from 'react-spinners';

interface Props {
  currentUser: User;
}

const ListingsRouter: React.FC<Props> = ( p ) => {
  const nav = useNavigate();

  // if(isLoading)
  //   return <LoadingSpinner size={60} />;

  return (
    <Routes>
      <Route path='/' element={<Navigate to='./view' replace />} />
      <Route path='/view' element={<ListingsGrid />} />
      <Route path='/edit' element={ <EditListings currentUser={p.currentUser} />} />
      <Route path='*' element={<Navigate to='/404' />} />
    </Routes>
  )
}

export type ListingFormType = {
  [key: string]: string | number | undefined;
  title: string;
  price: string | number;
  year: string | number;
  make: string;
  model: string;
  odometer: string | number;
  body_type: string;
  description: string;
  is_sold: number;
  id?: string;
}

export default ListingsRouter;
import React, { useEffect, useState } from 'react';
import styles from './Grid.module.css';

type GridProps = {
  children?: React.ReactNode;
}

const Container: React.FC<GridProps> = ( p ) => {
  return (
    <div className={styles.ctn}>
      {p.children}
    </div>
  );
}

type ItemProps = {
  children?: React.ReactNode;
}

const Item: React.FC<ItemProps> = ( p ) => {

  return (
    <div className={styles.item}>
      {p.children}
    </div>
  );
}


const Grid = {
  Container: Container,
  Item: Item
}

export default Grid;
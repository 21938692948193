import React, { useCallback, useContext, useEffect, useState } from 'react';
import FirebaseAuth, { 
  signInWithEmailLink,
  sendSignInLinkToEmail,
  ActionCodeSettings,
  isSignInWithEmailLink,
  signOut,
} from 'firebase/auth';
import { auth } from 'services';

type AuthContextType = {
  isLoggedIn: boolean;
  currentUser: FirebaseAuth.User | undefined;
  isLoading: boolean;
  sendLoginLink: (email: string) => void;
  logout: () => void;
  login: () => Promise<boolean>;
}

const AuthContext = React.createContext({} as AuthContextType);

type Props = {
  children?: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ( p ) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<FirebaseAuth.User | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if(user) {
        console.log('Authenticated.');
        setIsLoggedIn(true);
        setCurrentUser(user);
      } else {
        console.log('Requires authentication.');
        setIsLoggedIn(false);
        setCurrentUser(undefined);
      }
      setIsLoading(false);
    })
  }, [])
  
  const sendLoginLink = useCallback(async (email: string) => {
    const options: ActionCodeSettings = {
      handleCodeInApp: true,
      url: process.env.REACT_APP_LINK_URL as string,
    }
    
    await sendSignInLinkToEmail(auth, email, options).then(() => {
      console.log(`If we recognize it, an e-mail will be sent to ${email}. Remember to check your spam folder.`);
      window.localStorage.setItem('signInEmail', email);
    }).catch((e) => {
      console.log('Something bad happened.', e);
    });
  }, [auth])

  const logout = useCallback(async () => {
    await signOut(auth).then(() => {
      console.log('Successfully signed out.');
    }).catch((e) => {
      console.log('Something bad happened.', e);
    })
  }, [auth])

  const login = useCallback(async (): Promise<boolean> => {
    if(isSignInWithEmailLink(auth, window.location.href)) {
      console.log('Login link valid');
      var email = window.localStorage.getItem('signInEmail');
      if(!email) {
        email = window.prompt('Please provide your email for confirmation.');
      }
      await signInWithEmailLink(auth, email as string, window.location.href).then((result) => {
        console.log('Successful login:', result);
        window.localStorage.removeItem('signInEmail');
        return true;
      }).catch((e) => {
        console.log('Failed to login:', e);
      })
    }
    return false;
  }, [auth, window.location.href])

  const value = {
    isLoggedIn,
    currentUser,
    isLoading,
    sendLoginLink,
    logout,
    login
  };

  return (
    <AuthContext.Provider value={value}>
      {p.children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  return useContext(AuthContext);
}

export { AuthContext, AuthProvider, useAuth }
import React, { useEffect } from 'react';
import { useAuth } from 'contexts';
import { useNavigate } from 'react-router-dom';

type Props = {};

const Portal: React.FC<Props> = ( p ) => {
  const { login, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(isLoggedIn) {
      console.log('User is already authenticated')
      navigate('/console');
    } else {
      console.log('Checking arrival link in portal')
      const tryLogin = async () => {
        await login().then((success) => {
          if(success)
            navigate('/console');
          else
            navigate('/');
        });
      }
      tryLogin();
    }
  }, [])


  return <>Portal</>;
}

export default Portal;
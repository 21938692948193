import React, { LegacyRef, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router";
import { useAuth } from 'contexts';
import { Button } from 'components';
import { AiOutlineLogout as LogoutIcon } from 'react-icons/ai'; 
import { BsPlusLg as PlusIcon } from 'react-icons/bs';
import { ImCancelCircle as CancelIcon } from 'react-icons/im';
import styles from "./Nav.module.css";

interface Props {};

const Nav: React.FC<Props> = ( p ) => {
  const ref = useRef<HTMLDivElement>();
  const { logout } = useAuth();
  const [dynamicContent, setDynamicContent] = useState<React.ReactNode[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    var update = null;
    switch(location.pathname) {
      case '/console/listings/view': 
        update = 
          <Button label='Add Listing' onClick={() => navigate('./listings/edit')}> 
            <PlusIcon />
          </Button>; 
        break;
      case '/console/listings/edit': 
        update = 
          <Button label='Cancel Edit Listing' onClick={() => navigate('./listings/view')}>
            <CancelIcon />
          </Button>;
        break;
    }
    setDynamicContent([update]);
  }, [location.pathname])

  return (
    <>
      <div ref={ref as LegacyRef<HTMLDivElement>} className={styles.ctn}>
        <>
          {dynamicContent.map((item, i) => (<div key={i} > {item} </div>) )}
        </>
        <>
          <Button label='Logout' onClick={logout}> <LogoutIcon /> </Button>
        </>
      </div>
    </>
  )
}

export default Nav;
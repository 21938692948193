import React, { useState, useEffect } from 'react';
import styles from './TaggedImage.module.css';
import { ClipLoader as Loader } from 'react-spinners';

interface Props {
  image: string;
  isSold?: boolean;
  size?: string;
  noBg?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
 }

// 4:3
const TaggedImage: React.FC<Props> = ( p ) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <div onClick={p.onClick} className={styles.ctn} style={{ backgroundColor: isLoading ? 'white' : 'transparent' }}>
      <img src={p.image} 
        className={p.size === 'cover' ? styles.reel : styles.thumbnail}
        onLoad={(e) => setIsLoading(false)}
      />
      {isLoading ?
        <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loader size={60} />
        </div> : null
      }
      <div className={styles.bg} style={{ backgroundImage: p.noBg ? '' : `url(${p.image})` }} />
      { p.isSold && <div className={styles.soldTag}> Sold </div> }
    </div>
  );
}

export default TaggedImage;
import React from 'react';
import styles from './Modal.module.css';

type Props = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClose?: () => void;
};

const Modal: React.FC<Props> = ( p ) => {

  return (
    <div className={styles.ctn} style={p.style}>
      {p.children}
    </div>
  );
};

export default Modal;
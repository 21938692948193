import React, { LegacyRef, SyntheticEvent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import inputStyles from 'assets/styles/input.module.css';
import { Button, buttonStyles } from 'components';
import { useAuth } from 'contexts';

type Props = {}

const Login: React.FC<Props> = ( p ) => {
  const { sendLoginLink, isLoggedIn } = useAuth();
  const inputRef = useRef<HTMLInputElement>();
  const btnRef = useRef<HTMLButtonElement>();
  const navigate = useNavigate();

  useEffect(() => {
    if(isLoggedIn)
      navigate('/console');
  }, [isLoggedIn])

  const handleLogin = (e: SyntheticEvent) => {
    if(!inputRef.current || !btnRef.current)
      return;
    
    inputRef.current.disabled = true;
    btnRef.current.innerHTML = 'Check your e-mail';
    btnRef.current.classList.add(buttonStyles.pressed);
    
    
    var email = inputRef.current.value;
    // todo: enforce e-mail syntax
    sendLoginLink(email);
  }

  if(isLoggedIn)
    return <div>Redirecting.</div>;

  return (
    <div className={styles.ctn}>
      <div style={{fontSize: '1.5rem', marginTop: '0', marginBottom: '0.5rem'}}>Admin Portal</div>
        <input 
          ref={inputRef as LegacyRef<HTMLInputElement>} 
          className={inputStyles.ctn} 
          placeholder='E-mail address'
          onKeyDown={(e) => { e.key === 'Enter' && handleLogin(e) }}
        />
        <Button
          btnRef={btnRef}
          onClick={handleLogin} 
          label='Login' 
          style={{ width: '100%' }} 
        />
    </div>
  );
}

export default Login;
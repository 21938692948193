import React, { useEffect } from 'react';
// import styles from './Console.module.css';
import { useAuth } from 'contexts';
import { useNavigate, Routes, Route, Navigate } from 'react-router-dom';
import { Nav } from 'components';
import { DataProvider } from 'contexts';
import Listings from 'pages/Listings';

interface Props {};

const Console: React.FC<Props> = ( p ) => {
  const { isLoggedIn, currentUser, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(!isLoggedIn)
      navigate('/');
  }, [isLoggedIn])

  if(!isLoggedIn || !currentUser)
    return <></>

  return (
    <DataProvider>
      <Nav />
      <Routes>
        <Route path='/' element={<Navigate to="./listings" replace />} />
        <Route path='/listings/*' element={<Listings currentUser={currentUser} />} />
        <Route path='*' element={<Navigate to="/404" /> } />
      </Routes>
    </DataProvider>
  );
}

export default Console;
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { SalesAPI } from 'services';
import ReCAPTCHA from 'react-google-recaptcha';

type DataContextType = {
  listingData: [
    any[], 
    () => Promise<void>, 
    any[], 
    (id: string, year: number, make: string, model: string, price: number) => void,
    (id: string) => void,
    boolean
  ];
};

const DataContext = React.createContext({} as DataContextType);
type Props = {
  children?: React.ReactNode;
}

const DataProvider: React.FC<Props> = ( p ) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reRef = useRef<ReCAPTCHA>(null);
  const [rawData, setRawData] = useState<Array<any>>([]);
  const [pendingData, setPendingData] = useState<Array<any>>([]);

  const fetchRawListings = useCallback(async () => {
    if(!reRef.current)
      return;
    setIsLoading(true);
    try {
      // const token = await reRef.current.executeAsync();
      // reRef.current.reset();
      // if(!token) {
      //   setIsLoading(false);
      //   return;
      // }
      const token = '';
      const response = await SalesAPI.getListings(token);
      console.log('Response:', response);
      const data = response.data as Array<any>;
      data.sort((a, b) => parseInt(b.id) - parseInt(a.id));
      setRawData(data);
//todo pending
      console.log('Thumbnail data:', data.filter((datum, i) => datum.orderIndex === 0 ) );
    } catch(err) {
      console.error('Error occurred fetching listings:', err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRawListings();

  }, [reRef.current])


  function pendListing(id: string, year: number, make: string, model: string, price: number) {
    setPendingData(prev => {
      const update = [
        ...prev,
        { id: id, 
          year: year, 
          make: make, 
          model: model, 
          price: price 
        }
      ]
      return update;
    })
  }

  function unpendListing(id: string) {
    setPendingData(prev => prev.filter((datum) => datum.id !== id));
  }

  useEffect(() => {
    console.log('Pending listings:', pendingData);
  }, [pendingData])

  return (
    <DataContext.Provider value={{ listingData: [rawData, fetchRawListings, pendingData, pendListing, unpendListing, isLoading] }}>
      <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY ?? ''} size='invisible' ref={reRef} />
      {p.children}
    </DataContext.Provider>
  );
}

const useData = () => {
  return useContext(DataContext);
}

export { DataProvider, useData, DataContext };
import axios from 'axios';
import { ListingFormType } from 'pages/Listings';
import { formToJSON } from 'axios';

const host = process.env.REACT_APP_API_HOST;

const getMediaPlaceholderURL = () => {
  // return `${host}/media/placeholder.png`;
  return "https://www.dropbox.com/s/wroox1pvjm030xv/placeholder.png?dl=0&raw=1";
}

const getMediaURL = (id: string, resource: string) => {
  // return `${host}/media/${id}/${resource}`;
  return resource;
}

const getListings = async (token: string) => {
  return axios({
    method: 'post', 
    baseURL: host, 
    url: '/sales/getListings',
    params: {
      token
    }
  });
};

const addListing = async (form: ListingFormType, media: File[], idToken: string | Promise<string>) => {
  const formData = new FormData();
  for(const k in form)
    formData.append(`${k}`, form[k] as string);
  for(var i = 0; i < media.length; i++)
    formData.append(`${i}`, media[i], media[i].name);

  console.log('Form data submitted:', formToJSON(formData));
  if(!(typeof idToken === 'string')) {
    try {
      idToken = await idToken;
    } catch(err) {
      console.log('Authentication error', err);
      return;
    }
  }
  return axios({
    method: 'post',
    headers: { 
      'Authorization': `${idToken}`
    },
    baseURL: host, 
    url: '/sales/addListing',
    data: formData,
  }).then(() => { console.log('Add listing successful.') });
};

const editListing = async (form: ListingFormType, media: (File | string)[], deletedMedia: string[], idToken: string | Promise<string>) => {
  console.log('form submitted', form)
  const formData = new FormData();
  for(const k in form)
    formData.append(`${k}`, form[k] as string);

  const existingMedia = [];
  for(var i = 0; i < media.length; i++) {
    if(media[i] instanceof File) {
      var f = media[i] as File;
      formData.append(`${i}`, f, f.name);
    } else {
      existingMedia.push({ order: i, name: media[i] });
    }
  }
  formData.append('existing_media', JSON.stringify(existingMedia));
  formData.append('deleted_media', JSON.stringify(deletedMedia));
  
  console.log('Form data to submit:', formToJSON(formData));

  if(!(typeof idToken === 'string')) {
    try {
      idToken = await idToken;
    } catch(err) {
      console.log('Authentication error', err);
      return;
    }
  }
  return axios({
    method: 'post',
    headers: { 
      'Authorization': `${idToken}`
    },
    baseURL: host, 
    url: '/sales/editListing',
    data: formData,
  }).then(() => { console.log('Edit listing successful.') });
}

const deleteListing = async (id: number, idToken: string | Promise<string>) => {
  if(!(typeof idToken === 'string')) {
    try {
      idToken = await idToken;
    } catch(err) {
      console.log('Authentication error', err);
      return;
    }
  }
  return axios({
    method: 'post',
    headers: {
      'Authorization': `${idToken}`
    },
    baseURL: host,
    url: '/sales/deleteListing',
    params: {
      id
    }
  });
}

const SalesAPI = {
  getMediaURL,
  getMediaPlaceholderURL,
  getListings,
  addListing,
  deleteListing,
  editListing
};

export default SalesAPI;


import React, { useState, useContext, useEffect, Children, useRef, SyntheticEvent } from 'react';
import styles from './FiniteCarousel.module.css';
import { BsChevronLeft as ArrowLeft } from 'react-icons/bs';
import { BsChevronRight as ArrowRight } from 'react-icons/bs';

type DisplayProps = {
  children?: React.ReactNode;
}

const Container: React.FC<DisplayProps> = ( p ) => {
  // const [selected, setSelected] = useState<number>(-1);
  const [translation, setTranslation] = useState<number>(0);
  const trackRef = useRef<HTMLDivElement>(null);
  
  const onSlide = (e: SyntheticEvent, left: boolean) => {
    e.preventDefault();
    if(!trackRef.current)
      return;

      setTranslation(prev => prev + (left ? -1 : 1));
      trackRef.current.style.setProperty('transform', `translateX(calc(${translation + (left ? -1 : 1)} * (var(--item-width) + 0.25em)))`);
  }

  return (
    <div className={styles.display}>
        <div className={styles.track} ref={trackRef}>
          {p.children}
        </div>
          <div className={styles.btnCtn}>
            <div className={styles.leftBtn} style={translation < 0 ? {} : { opacity: 0, pointerEvents: 'none' } } onClick={(e) => onSlide(e, false)}>
              <ArrowLeft />
            </div>
            <div className={styles.rightBtn} style={translation > -1 * Children.count(p.children) + 1 ? {} : { opacity: 0, pointerEvents: 'none' }} onClick={(e) => onSlide(e, true)}>
              <ArrowRight />
            </div>
          </div>
    </div>
  );
}

type ItemProps = {
  src?: string;
  pos?: string;
  children?: React.ReactNode;
  className?: string;
}

const Item: React.FC<ItemProps> = ( p ) => {
  return (
    <div
      className={`${styles.item} ${p.className}`} 
      style={{ 
        backgroundImage: p.src ? `url(${p.src})` : '',
        backgroundPosition: p.pos
      }}
    >
      {p.children}
    </div>
  );
}

const FiniteCarousel = {
  Container,
  Item
}

export default FiniteCarousel;
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Login, Console, Portal } from 'pages';
import { useAuth } from 'contexts';
import { Helmet } from 'react-helmet';

function App() {
  const { isLoading } = useAuth();

  if(isLoading)
    return null;

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title> Admin | Kieu Auto Service </title>
        <link rel='icon' type='image/png' href='https://www.dropbox.com/s/org08rl0cc2a6z5/kieu.png?raw=1' sizes='16x16' />
      </Helmet>
      <div className='app'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path='/console/*' element={<Console />} />
            <Route path='/verify' element={<Portal />} />
            <Route path='*' element={<div> 404 </div>} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'components';
import { EditMedia, EditForm, ListingFormType as Form } from 'pages/Listings';
import { SalesAPI } from 'services';
import styles from './EditListings.module.css';
import { User } from 'firebase/auth';
import { BsCheck2Square as PostAdIcon } from 'react-icons/bs';
import { MdCancel as DeleteIcon } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router';
import { BarLoader as UploadingLoader } from 'react-spinners';
import { useData } from 'contexts';

const EMPTY_FORM = {
  title: "",
  price: "",
  year: "",
  make: "",
  model: "",
  odometer: "",
  body_type: "",
  description: "",
  is_sold: 0
}

// const EMPTY_MEDIA: File[] | Promise<File[]> = (async () => {
//   const empty = [];
//   var toAdd;
//   try {
//     toAdd = await SalesAPI.getMediaPlaceholderFile()
//     empty.push(toAdd);
//   } catch(err) {
//     console.log(err);
//   }
//   return empty;
// })();

interface Props {
  // data: any[];
  // refreshData: () => Promise<void>;
  // pendListing: (id: string, year: number, make: string, model: string, price: number) => void;
  // unpendListing: (id: string) => void;
  currentUser: User;
}

const EditListings: React.FC<Props> = ( p ) => {
  const [data, refreshData, pendingData, pendListing, unpendListing, isLoading] = useData().listingData;
  const initialData = data;

  const location = useLocation();
  const id = location.state?.id;
  const [mediaBuffer, setMediaBuffer] = useState<(File | string) []>(getMediaById(id, initialData));
  const [deletedMedia, setDeletedMedia] = useState<string[]>([]);
  const [formBuffer, setFormBuffer] = useState<Form>(getFormById(id, initialData));

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const nav = useNavigate();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    console.log('To delete', deletedMedia);
  }, [deletedMedia])

  useEffect(() => {
    setMediaBuffer(getMediaById(id, data));
    setFormBuffer(getFormById(id, data));
  }, [id])

  function finishChanges() {
    setFormBuffer(prev => {
    for(var i in prev)
      prev[i] = '';
    prev['is_sold'] = 0;
    return prev;
    });
    nav('../view');
  }

  const handleAddListing = () => {
    const form = document.getElementById('edit_listings_form') as HTMLFormElement;
    if(!form || !form.reportValidity()) { // todo
      return;
    }
    
    const formBufferWithId = { ...formBuffer };
    formBufferWithId.id = Date.now().toString();
    pendListing(formBufferWithId.id, formBufferWithId.year as number, formBufferWithId.make, formBufferWithId.model, formBufferWithId.price as number);
    SalesAPI.addListing(formBufferWithId, mediaBuffer as File[], p.currentUser.getIdToken())
      .then(() => {
        refreshData();
        unpendListing(formBufferWithId.id as string);
      })
    finishChanges();
  }

  const handleEditListing = () => {
    const form = document.getElementById('edit_listings_form') as HTMLFormElement;
    if(!form || !form.reportValidity()) { // todo
      return;
    }
    const id = formBuffer.id as string;
    console.log('Editing listing', id);
    pendListing(formBuffer.id as string, formBuffer.year as number, formBuffer.make, formBuffer.model, formBuffer.price as number);
    SalesAPI.editListing(formBuffer, mediaBuffer, deletedMedia, p.currentUser.getIdToken())
      .then(() => {
      refreshData();
      unpendListing(id);
    });
    finishChanges();
  }

  const handleDeleteListing = () => {
    console.log('Deleting', id);
    setIsUploading(true);
    SalesAPI.deleteListing(id, p.currentUser.getIdToken())
      .then(() => {
        refreshData().then(() => {
          setFormBuffer(EMPTY_FORM);
          nav('../view');
        })
      }).finally(() => setIsUploading(false));
  }

  return (
    <div className={styles.ctn}>
      <div className={styles.modalBg} style={{ display: deleteModalOpen ? 'flex' : 'none' }}>
        <Modal>
          <div style={{ fontSize: '2em', marginBottom: '0.5em' }}>Ad deletion - are you sure?</div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button label='Delete' style={{ backgroundColor: 'red', color: 'white' }} onClick={handleDeleteListing}/>
            <Button label='Cancel' style={{ marginLeft: '0.5em' }} onClick={(e) => setDeleteModalOpen(false)} />
          </div>
        </Modal>
      </div>
      <div className={styles.content}>
        <div className={styles.data}>
          <EditMedia id={id} media={[mediaBuffer, setMediaBuffer]} deletedMedia={[deletedMedia, setDeletedMedia]} isSold={formBuffer.is_sold ? true : false} />
          <EditForm form={[formBuffer, setFormBuffer]} />
        </div>
        <div className={styles.actions}>
          {isUploading ? 
          <>
            <div style={{ marginRight: '0.5em' }}>
              Uploading
            </div>
            <UploadingLoader />
          </> :
          <>
            {!id && 
              <Button label='Post Ad' className={styles.btn} onClick={handleAddListing}>
                <PostAdIcon />
              </Button>
            }
            {id && 
              <>
                <Button label='Delete This Ad' className={styles.deleteBtn} onClick={(e) => setDeleteModalOpen(true)}>
                  <DeleteIcon />
                </Button>
                <Button label='Save Changes' onClick={handleEditListing} className={styles.btn}>
                  <PostAdIcon />
                </Button>
              </>
            }
          </>
          }
        </div>
      </div>
    </div>
  );
}

const getFormById = (id: number | undefined, data: any[]) => {
  if(!id)
    return EMPTY_FORM;
  var relevant = data.filter( (datum) => ( datum.id === id ))
  if(!relevant.length)
    return EMPTY_FORM;
  const formUpdate = {
    id: relevant[0].id,
    title: relevant[0].title,
    price: relevant[0].price,
    year: relevant[0].year,
    make: relevant[0].make,
    model: relevant[0].model,
    odometer: relevant[0].odometer,
    body_type: relevant[0].body_type,
    description: relevant[0].description,
    is_sold: relevant[0].is_sold
  };
  return formUpdate;
}

const getMediaById = (id: number | undefined, data: any[]) => {
  if(!id) 
    return [];
  var relevant = data.filter( (datum) => ( datum.id === id ))
  if(!relevant.length || !relevant[0].name)
    return [];
  const mediaUpdate: string[] = [];
  relevant = relevant.sort((a, b) => a.orderIndex - b.orderIndex);
  for(const i of relevant)
    mediaUpdate.push(i.name as string);
  return mediaUpdate;
}

export default EditListings;
import React, { LegacyRef, MutableRefObject, SyntheticEvent, useEffect, useRef } from 'react';
import styles from './Button.module.css';

type props = {
  label: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset' | undefined;
  btnRef?: MutableRefObject<HTMLButtonElement | undefined>;
  disable?: boolean;
  singleClickText?: string;
  children?: React.ReactNode;
}

const Button: React.FC<props> = (p) => {
  const backupRef = useRef<HTMLButtonElement>();
  const ref = p.btnRef ?? backupRef; 

  return (
    <button 
      type={p.type}
      onClick={p.onClick} 
      className={`${styles.ctn} ${p.className}`} 
      style={p.style}
      ref={(p.btnRef ?? ref) as LegacyRef<HTMLButtonElement>}
    >
      <div>
        {p.label}
      </div>
      <div style={{ marginLeft: '0.5em', display: React.Children.count(p.children) > 0 ? 'flex' : 'none', justifyContent: 'center', fontSize: '1.25rem'}}>
       {p.children}
      </div>
    </button>
  );
}

export default Button;
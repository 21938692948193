import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './ListingsGrid.module.css';
import ListingCard from './ListingCard';
import { Grid } from 'components';
import { BiNews as AdsIcon } from 'react-icons/bi';
import { useData } from 'contexts';

type Props = {
  // data: Array<any>;
  // pendingData: Array<any>;
};

const ListingsGrid: React.FC<Props> = ( p ) => {
  const [data, refreshData, pendingData, pendListing, unpendListing, isLoading] = useData().listingData;

  const nav = useNavigate();
  return (
    <div className={styles.ctn}>
      <div style={{ fontSize: '1.5rem', display: 'flex', alignItems: 'center' }}>
        Active Listings <AdsIcon style={{ fontSize: '1.2em', marginLeft: '0.3em' }}/>
      </div>
      <Grid.Container>
        {
          pendingData.map((datum, i) => (
            data.findIndex(existing => existing.id === datum.id) === -1 &&
            <Grid.Item key={i}>
              <ListingCard
                onClick={(e) => e.preventDefault()}
                id={datum.id}
                title={`${datum.year} ${datum.make} ${datum.model}`}
                thumbnail={''}
                year={datum.year}
                make={datum.make}
                model={datum.model}
                price={datum.price}
                isSold={false}
              />
            </Grid.Item>
          ))
        }
        {
          data.filter(datum => datum.orderIndex === 0 || datum.orderIndex === null).map((datum, i) => 
            <Grid.Item key={i}>
              <ListingCard
                onClick={ 
                  pendingData.findIndex(pending => pending.id === datum.id) === -1 ? 
                    (e) => { nav( '../edit', { state: { id: datum.id }}) } 
                    : 
                    (e) => {} 
                }
                id={datum.id}
                title={datum.title}
                thumbnail={pendingData.findIndex(pending => pending.id === datum.id) === -1 ? datum.name : ''}
                year={datum.year}
                make={datum.make}
                model={datum.model}
                price={datum.price}
                isSold={datum.is_sold ? true : false}
              />
            </Grid.Item>  
          )
        }
      </Grid.Container>
    </div>
  );
}

export default ListingsGrid;
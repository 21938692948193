import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/global.css';
import App from './App';
import { AuthProvider } from 'contexts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// console.log = function () {};

root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);


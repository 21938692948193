import React from 'react';
import styles from './ListingsGrid.module.css';
import { SalesAPI } from 'services';
import { TaggedImage } from 'components';
import { BarLoader } from 'react-spinners';

type Props = {
  id: string;
  thumbnail: string;
  title: string;
  year: number;
  make: string;
  model: string;
  price: number;
  isSold: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
};

const ListingCard: React.FC<Props> = ( p ) => {
  return(
    <div onClick={p.onClick} className={styles.cardCtn}>
      
      {p.thumbnail ? 
      <TaggedImage 
        isSold={p.isSold}
        size='cover'
        image={p.thumbnail ? `${SalesAPI.getMediaURL(p.id, p.thumbnail)}` : `${SalesAPI.getMediaPlaceholderURL()}`}
      /> :
      <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <BarLoader />
      </div>
      }
      <div className={styles.cardFooter}>
        <div className={styles.cardFooterText}>
          <div>{`${p.title}`}</div>
          <div>{`${p.year} ${p.make} ${p.model}`}</div>
          <div>{`\$${p.price.toLocaleString()}`}</div>
        </div>
      </div>
    </div>
  );
}

export default ListingCard;